body,
.body {
  font-family: $default-font-families;
  font-weight: $default-font-weight;
  font-size: $default-font-size;
  line-height: $default-line-height;
}

.heading-1 {
  font-size: 2.6rem;
  font-weight: 700;
  color: $color-blue;
  line-height: 2.95rem;

  @include desktop {
    font-size: 5.2rem;
    line-height: 5.2rem;
  }
}

.heading-3 {
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 400;

  @include desktop {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
}

.heading-4 {
  font-size: 1.6rem;
  line-height: 2.25rem;
  font-weight: 700;

  @include desktop {
    font-size: 3.2rem;
    line-height: 4.5rem;
  }
}

.paragraph {
  font-size: 1.4rem;
  line-height: 1.9rem;

  @include desktop {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
