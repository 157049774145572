.progress {
  margin: 2rem auto 4.5rem;
  width: fit-content;
  list-style-type: none;
  display: flex;

  @include desktop {
    margin-top: 7.5rem;
  }

  .step {
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid $color-grey;
    border-radius: 50%;
    margin: 0 0.2rem;

    @include desktop {
      width: 1.6rem;
      height: 1.6rem;
      margin: 0 0.3rem;
    }

    &--active {
      background: $color-green;
    }

    &--complete {
      background: $color-light-grey;
    }
  }
}
