.holder {
  position: fixed;
  left: 100vw;
}

.download {
  width: 1080px;
  height: 1080px;
  position: relative;

  &__image {
    width: 100%;

    &__rapp {
      position: absolute;
      bottom: 6.5%;
      left: 2.5%;
      width: 26.4%;

      text-align: center;
      line-height: normal;

      &__text {
        width: 75%;
      }

      &__logo {
        width: 70%;
      }
    }
  }

  &__table {
    border: solid 5px #c2d430;
    width: 702px;
    height: 1032px;
    position: absolute;
    right: 24px;
    top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 1fr 1fr 1fr;

    .box1 {
      grid-column-start: 1;
      grid-column-end: 3;

      border-bottom: 5px solid #0090bb;
    }
    .box2 {
      grid-column-start: 1;
      grid-column-end: 3;

      padding-top: 25px;
      padding-bottom: 20px;

      border-bottom: 5px solid #0090bb;
    }
    .box3 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      border-bottom: 5px solid #0090bb;
    }
    .box4 {
      border-left: 5px solid #0090bb;

      border-bottom: 5px solid #0090bb;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 7% 0;
    }
    .box5 {
      border-bottom: 5px solid #0090bb;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .box6 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .box7 {
      grid-row-start: 4;
      grid-row-end: 6;
      grid-column-start: 2;
      grid-column-end: 3;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-left: 5px solid #0090bb;
    }
  }

  &__name {
    font-size: 70px;
    font-weight: 900;
    text-align: center;
    width: 100%;
    line-height: 1;
    margin-top: 7px;
  }

  &__large-text {
    width: 100%;
    text-align: center;
    font-size: 45px;
    font-weight: 900;
    line-height: 1;

    margin-top: 10px;
    margin-bottom: 44px;
    // padding-bottom: 38px;
  }

  &__header {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 900;
    line-height: 1;

    &--gingerbread1,
    &--snow1 {
      color: $color-white;
    }

    &--holly2 {
      color: #0090bb;
    }

    &--mittens1 {
      color: #0090ba;
    }
  }

  &__body {
    width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 1.25;
    font-weight: 500;
    padding: 20px 60px 0;

    &--small {
      padding: 104px 30px;
    }
  }

  &__h6 {
    width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    padding: 0 30px;

    &--black {
      color: $color-black !important;
      margin-top: 0.5rem;

      &--large {
        margin-top: 3rem;
        font-size: 50px;
        line-height: 50px;
        font-weight: 900;
      }
    }
  }

  &--fluid {
    width: 100%;
    height: fit-content;

    @include desktop {
      min-width: 61.2rem;
      position: absolute;
      right: 0;
    }

    .download__table {
      width: 65%;
      height: 92%;

      right: 2.2%;
      top: 2.2%;

      .box2 {
        padding-top: 2.8vw;
        padding-bottom: 2.8vw;

        @include desktop {
          padding-top: 1.8rem;
          padding-bottom: 1.8rem;
        }
      }

      &,
      .box1,
      .box2,
      .box3,
      .box4,
      .box5,
      .box7 {
        border-width: 2px;
      }

      @include desktop {
        height: 94%;
      }
    }

    .download__name {
      font-size: 5vw;
      margin-top: 2vw;

      @include desktop {
        font-size: 4rem;
        margin-top: 1.5rem;
      }
    }

    .download__large-text {
      font-size: 3vw;
      margin-top: 1vw;
      margin-bottom: 2vw;

      @include desktop {
        font-size: 2.5rem;
        margin-top: 0.4rem;
        margin-bottom: 1.8rem;
      }
    }

    .download__header {
      font-size: 2.2vw;

      @include desktop {
        font-size: 1.8rem;
      }
    }

    .download__body {
      font-size: 0.8rem;
      padding: 0.4rem 1rem 0;

      &--small {
        padding: 3.8rem 1.8rem;
      }

      @include desktop {
        font-size: 1.3rem;
        padding: 0.7rem 6rem 0;

        &--small {
          padding: 6.5rem 3rem;
        }
      }
    }

    .download__h6 {
      font-size: 0.8rem;
      padding: 0 0rem;
      line-height: 1.2;

      @include desktop {
        font-size: 1.4rem;
        padding: 0 2.5rem;
      }

      &--skinny {
        @include desktop {
          width: 80%;
        }
        width: 100%;
      }

      &--skinny2 {
        width: 90%;
      }
      &--black {
        &--large {
          margin-top: 0.2vw;

          @include desktop {
            margin-top: 1rem;
            font-size: 2.8rem;
          }
        }
      }
    }
  }

  &--gingerbread1 {
    .download__table {
      border-color: #c2d430;

      .box1,
      .box2,
      .box3,
      .box4,
      .box5,
      .box7 {
        border-color: #c2d430;
      }
    }
    .download__large-text {
      color: $color-white;
    }
    .download__h6 {
      color: $color-white;
    }
  }

  &--snow1 {
    .download__table {
      border-color: #f7da07;

      .box1,
      .box2,
      .box3,
      .box4,
      .box5,
      .box7 {
        border-color: #f7da07;
      }
    }
    .download__large-text {
      color: $color-white;
    }
    .download__h6 {
      color: $color-white;
    }
  }

  &--holly2 {
    .download__table {
      border-color: #0090bb;

      .box1,
      .box2,
      .box3,
      .box4,
      .box5,
      .box7 {
        border-color: #0090bb;
      }
    }
    .download__large-text {
      color: $color-black;
    }

    .download__name {
      color: #0090bb;
    }

    .download__h6 {
      color: #0090bb;
    }
  }

  &--mittens1 {
    .download__table {
      border-color: #0090ba;

      .box1,
      .box2,
      .box3,
      .box4,
      .box7 {
        border-color: #0090ba;
      }
    }

    .download__large-text {
      color: #0090ba;
    }

    .download__h6 {
      color: #0090ba;
    }
  }
}

.icon {
  width: 100px;

  margin-bottom: 25px;

  &--fluid {
    width: 25%;

    margin-bottom: 1rem;

    @include desktop {
      margin-bottom: 1.8rem;
    }
  }
}
