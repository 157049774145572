.input {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;

  &--radio {
    margin-top: 2.5rem;

    @include desktop {
      flex-direction: row;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--radio2 {
    margin-top: 1.5rem;

    @include desktop {
      flex-direction: row;
      width: fit-content;
      max-width: 120rem;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
  }

  &__textarea {
    resize: none;
    width: 100%;
    height: 5rem;
    max-width: 50rem;
    border-color: $color-grey;

    font-family: inherit;

    font-size: 1.4rem;
    line-height: 1.8rem;

    padding: 1rem 1rem;

    margin-top: 1rem;

    @include desktop {
      font-size: 1.8rem;
      line-height: 2.3rem;
      height: 10rem;
      max-width: 54rem;
      margin-top: 2rem;
      padding: 1.8rem 2.3rem;
    }

    &::placeholder {
      color: $color-grey;
    }
  }

  &__checkmark {
    margin-top: 0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    background: $color-white;
    border: 1px solid $color-grey;
    display: block;
    position: relative;

    &:after {
      content: '';
      top: 0.1rem;
      left: 0.1rem;
      width: 1rem;
      height: 1rem;
      position: absolute;
      background: $color-grey;
      display: none;

      @include desktop {
        top: 0.3rem;
        left: 0.3rem;
        width: 2rem;
        height: 2rem;
      }
    }

    @include desktop {
      width: 2.8rem;
      height: 2.8rem;

      margin-top: 2rem;
    }
  }

  &__checkmark2 {
    margin-top: 0.7rem;
    background: $color-white;
    border: 1px solid $color-grey;
    display: block;
    width: 20rem;
    padding: 0.9rem 0.9rem;

    @include desktop {
      width: 26.5rem;
      padding: 1.2rem 0;
      margin-top: 2rem;
    }

    &--long {
      min-height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include desktop {
        min-height: 8rem;
        padding: 1.2rem 2rem;
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 2rem 3rem;

    font-size: 1.4rem;
    line-height: 1.8rem;

    @include desktop {
      font-size: 2rem;
      line-height: 2.8rem;
      width: 20rem;

      margin: 0 1.5rem;
    }
  }

  &__label2 {
    margin: auto 2rem 0.2rem;

    @include desktop {
      margin: 0 1rem;

      width: 26.5rem;
    }
  }

  &__image {
    width: 5rem;
    height: 5rem;

    margin-bottom: 1rem;

    @include desktop {
      width: 10rem;
      height: 10rem;

      margin-bottom: 2rem;
    }
  }

  $this: &;

  &__radio {
    position: absolute;
    opacity: 0;
    &:checked ~ #{$this}__checkmark:after {
      display: block;
    }
  }

  &__radio2 {
    position: absolute;
    opacity: 0;
    &:checked ~ #{$this}__checkmark2 {
      background: #979797;
    }
  }

  &--number {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    max-width: 25rem;
    margin: 2rem auto 8.2rem;
    border-color: $color-grey;

    font-family: $default-font-families !important;

    @include desktop {
      max-width: 45rem;
      margin-top: 3.8rem;
      margin-bottom: 0;
    }

    input {
      width: 64%;
      height: 3.75rem;
      padding: 1rem;

      &::placeholder {
        color: $color-grey;
      }

      @include desktop {
        font-size: 1.8rem;
        line-height: 2.3rem;
        padding: 2.4rem;
      }
    }

    label {
      margin: 0 auto 0 1.5rem;
      font-size: 1.25rem;
      font-weight: 700;
      text-transform: uppercase;
      cursor: default;

      @include desktop {
        font-size: 1.7rem;
      }
    }
  }

  // &__checkmark2 {
  //   margin-top: 0.7rem;
  //   background: $color-white;
  //   border: 1px solid $color-grey;
  //   display: block;
  //   width: 20rem;
  //   padding: 0.9rem 0.9rem;

  //   // &:after {
  //   //   content: '';
  //   //   top: 0.1rem;
  //   //   left: 0.1rem;
  //   //   width: 1rem;
  //   //   height: 1rem;
  //   //   position: absolute;
  //   //   background: $color-grey;
  //   //   display: none;

  //   //   @include desktop {
  //   //     top: 0.3rem;
  //   //     left: 0.3rem;
  //   //     width: 2rem;
  //   //     height: 2rem;
  //   //   }
  //   // }

  //   @include desktop {
  //     width: 26.5rem;
  //     padding: 1.2rem 0;
  //     margin-top: 2rem;
  //   }

  //   &--long {
  //     min-height: 7rem;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     @include desktop {
  //       min-height: 8rem;
  //       padding: 1.2rem 2rem;
  //     }
  //   }
  // }

  // &--radio {
  //   margin-top: 2.5rem;

  //   @include desktop {
  //     flex-direction: row;
  //     width: fit-content;
  //   }

  //   label {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     margin: auto 2rem 3rem;

  //     @include desktop {
  //       font-size: 2rem;
  //       line-height: 2.8rem;
  //       width: 20rem;

  //       margin: 0 1.5rem;
  //     }
  //   }
  // }

  // &--radio2 {
  //   margin-top: 2.5rem;

  //   @include desktop {
  //     flex-direction: row;
  //     flex-wrap: wrap;
  //     justify-content: center;
  //   }

  //   label {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     margin: auto 2rem 0.2rem;

  //     @include desktop {
  //       font-size: 2rem;
  //       line-height: 2.8rem;
  //       // width: 20rem;

  //       margin: 0 1.5rem;
  //     }
  //   }
  // }

  // $this: &;

  // &__radio {
  //   position: absolute;
  //   opacity: 0;
  //   &:checked ~ #{$this}__checkmark:after {
  //     display: block;
  //   }
  // }

  // &__radio2 {
  //   position: absolute;
  //   opacity: 0;
  //   &:checked ~ #{$this}__checkmark2 {
  //     background: #979797;
  //   }
  // }

  // &--noimage {
  //   @include desktop {
  //     margin-top: 5rem;
  //   }

  //   label {
  //     margin-bottom: 3.5rem;
  //   }
  // }

  // &--number {
  //   display: flex;
  //   flex-direction: row;
  //   width: 100%;
  //   justify-content: space-between;
  //   max-width: 45rem;
  //   margin-top: 2rem;
  //   border-color: $color-grey;

  //   input {
  //     width: 64%;
  //     height: 3.75rem;
  //     padding: 1rem;

  //     &::placeholder {
  //       color: $color-grey;
  //     }

  //     @include desktop {
  //       font-size: 1.8rem;
  //       line-height: 2.3rem;
  //       padding: 2.4rem;
  //     }
  //   }

  //   label {
  //     margin: 0 auto 0 1.5rem;
  //     font-size: 1.25rem;
  //     font-weight: 700;
  //     text-transform: uppercase;
  //     cursor: default;

  //     @include desktop {
  //       font-size: 1.7rem;
  //     }
  //   }
  // }
}
