// RESPONSIVE BREAKPOINTS
$xl-device-width: 75em; // 1200px
$lg-device-width: 62em; // 992px
$md-device-width: 48em; // 768px
$sm-device-width: 36em; // 576px

// LAYOUT WIDTHS
$max-width: 90em; // 1440px

// MARGINS AND PADDINGS
$default-gutter: 4.2rem;

// COLORS
$color-white: #ffffff;
$color-white-grey: #f7f7f7;
$color-light-grey: #dcdcd9;
$color-med-grey: #bfbfbf;
$color-grey: #979797;
$color-black: #000000;
$color-orange: #ff6b00;
$color-blue: #0090ba;
$color-green: #c2d502;

// TYPOGRAPHY
$default-font-families: 'DINPro', 'Tahoma', 'Geneva', sans-serif;
$default-font-size: 2.8rem;
$default-line-height: 3.8rem;
$default-font-weight: 400;

// ANIMATIONS
$default-transition-time: 0.3s;
$default-transition-easing: cubic-bezier(0.19, 0.71, 0.36, 0.93);
$default-transition: all;
