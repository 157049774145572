@font-face {
  font-family: 'DINPro';
  src: url('../../static/fonts/DINWebPro.woff') format('woff'),
    url('../../static/fonts/DINWebPro.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('../../static/fonts/DINOffcPro-Light.woff') format('woff'),
    url('../../static/fonts/DINOffcPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('../../static/fonts/DINOffcPro-Bold.woff') format('woff'),
    url('../../static/fonts/DINOffcPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('../../static/fonts/DINOffcPro-Black.woff') format('woff'),
    url('../../static/fonts/DINOffcPro-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
