*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
}

body {
  box-sizing: border-box;
}

html,
body {
  background: $color-white-grey;
}

a {
  &:visited,
  &:link {
    text-decoration: none;
    color: $color-black;
  }
}

a[href],
button,
textarea,
input[type='text'],
input[type='number'],
input[type='radio'],
input[type='checkbox'],
select {
  font-family: $default-font-families;

  &:active,
  &:focus {
    outline-color: $color-blue;
  }
}
