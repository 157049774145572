.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include desktop {
    min-height: 0;
  }

  &--sticky-progress {
    @include desktop {
      min-height: 100vh;
    }
  }
}

.container {
  margin: 0 auto;

  width: 100%;
  max-width: $max-width;

  flex: 1;

  @include desktop {
    padding-left: 19rem !important;
    padding-right: 19rem !important;
  }

  &--card {
    position: relative;
    @include desktop {
      margin-top: 9rem;
    }
  }
}

.landing {
  position: relative;

  padding-left: 2.1rem;
  padding-right: 2.1rem;

  @include desktop {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 0;
    padding-right: 0;
    margin-top: 5.5rem;
  }

  &__content {
    @include desktop {
      padding-right: 2rem;
      flex: 1 0 52%;
    }
  }

  &__heading {
    width: 100%;
    max-width: 52rem;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    margin-right: auto;
    margin-left: auto;
    white-space: pre-wrap;

    @include desktop {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    &--mobile {
      margin-top: 2.3rem;
      margin-bottom: 2.3rem;

      @include desktop {
        display: none;
      }
    }

    &--desktop {
      min-width: 38rem;
      margin-top: 4rem;
      margin-bottom: 3.4rem;

      @include mobile {
        display: none;
      }
    }
  }

  &__hero {
    width: 100%;
    text-align: center;

    margin-bottom: 1.8rem;

    @include desktop {
      text-align: right;
      margin-bottom: 0;
    }
  }

  &__image {
    width: 100%;
    max-width: 52rem;
  }

  &__paragraph {
    width: 100%;
    max-width: 52rem;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    margin-right: auto;
    margin-left: auto;

    @include desktop {
      max-width: 37.4rem;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    &:not(:first-of-type) {
      margin-top: 2rem;

      @include desktop {
        margin-top: 2.6rem;
      }
    }
  }

  &__cta {
    margin: 3.8rem auto 0;

    @include desktop {
      margin: 3.8rem 0 0;
    }
  }
}

.card {
  &__copy {
    // width: 37.4rem;
  }

  &__heading {
    margin: 1.5rem auto 0;
    width: 61.2rem;
    max-width: calc(100% - #{$default-gutter} - #{$default-gutter});
    white-space: pre-wrap;

    @include desktop {
      margin: 2.7rem 0 3.7rem;
      width: 37.4rem;
    }
  }

  &__image {
    margin: 1.5rem auto 0;
    width: 61.2rem;
    max-width: calc(100% - #{$default-gutter} - #{$default-gutter});

    @include desktop {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__paragraph {
    margin: 0 auto 2.5rem;
    width: 61.2rem;
    max-width: calc(100% - #{$default-gutter} - #{$default-gutter});

    @include desktop {
      margin: 0 0 2.5rem;
      width: 37.4rem;
    }
  }

  &__cta {
    max-height: 3.8rem;
    display: block;
    display: flex;
    align-items: center;
    margin: 1.5rem auto;

    @include desktop {
      margin: 0;
      margin-top: 2rem;
      width: 37.4rem;
      max-height: 5rem;
      height: 5rem;
    }
  }
}

.share-buttons {
  display: flex;
  justify-content: center;

  margin: 1rem auto 0;
  width: 61.2rem;
  max-width: calc(100% - #{$default-gutter} - #{$default-gutter});

  transform: scale(0.75);

  > * {
    margin: 0 2rem -1rem;
  }

  @include desktop {
    transform: initial;
    margin: 0;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    width: 37.4rem;
    max-width: 25%;
    justify-content: flex-start;

    > * {
      margin: 0 2rem -1rem 0;
    }
  }
}
