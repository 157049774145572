.cta {
  background: $color-orange;

  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 7.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;

  padding: 1.2rem 1.1rem 1.2rem;

  @include transition(background-color);

  @include desktop {
    font-size: 1.7rem;
    padding: 1.5rem 3.5rem 1.5rem;
  }

  &,
  &:link,
  &:active,
  &:visited {
    color: $color-white;
  }

  &:hover {
    background-color: invert($color-orange, 5%);
  }

  &:disabled {
    background: $color-med-grey;
  }

  &--link {
    padding: 0.6rem;
  }

  &--fluid {
    max-width: 19.5rem;
    width: 100%;

    @include desktop {
      max-width: 28rem;
    }
  }

  &--gray {
    background: $color-med-grey;
    margin-top: 1.9rem;
  }
}
