.question {
  text-align: center;

  padding: 2.8rem 2.1rem 0;

  @include desktop {
    padding: 5rem 0 0;
  }

  &__image {
    width: 5rem;
    height: 5rem;

    @include desktop {
      width: 10rem;
      height: 10rem;
    }
  }

  &__heading {
    margin-top: 1.2rem;

    @include desktop {
      margin-top: 2.4rem;
    }
  }

  &__question {
    max-width: 25rem;
    margin: 0 auto;

    @include desktop {
      max-width: 76rem;
    }
  }

  &__button-container {
    width: 100%;
    max-width: 50rem;
    display: flex;
    justify-content: center;

    margin: 3.2rem auto 0;

    @include desktop {
      margin-top: 4rem;
    }

    &__button {
      flex: 1;
      max-width: 12rem;

      &:first-child {
        margin-right: 1rem;
      }

      &:last-child {
        margin-left: 1rem;
      }

      @include desktop {
        max-width: 15.5rem;
      }
    }
  }
}
