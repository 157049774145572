.header {
  background: $color-white;

  height: 5.3rem;

  display: flex;

  justify-content: center;
  align-items: center;

  @include desktop {
    height: 7.7rem;
  }

  &__menu {
    display: flex;

    justify-content: center;
    align-items: center;

    font-size: 0.9rem;

    margin-top: -0.1rem;

    @include desktop {
      justify-content: space-between;
      font-size: 1.6rem;
    }
  }

  &__rapp {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: -1rem;
      margin-top: 0.5rem;

      @include desktop {
        margin-left: 0;
        margin-top: -0.2rem;
      }
    }

    &__logo {
      margin-left: 0.95rem;
      height: 1.85rem;

      @include desktop {
        height: 4.1rem;
        margin-left: 2.4rem;
      }
    }
  }

  &__hashtag {
    display: none;

    @include desktop {
      display: initial;
    }
  }
}
